import React, { useState } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Account from './Account';
import Logout from './Logout';
import Navhead from './Navhead';
import Nopage from './Nopage';
import SearchProduct from './SearchProduct';
import OrderOnline from './OrderOnline';
import Dashboard from './Dashboard';
import { Indian } from './Indian';
import { Tandoor } from './Tandoor';
import { Veg } from './Veg';
import Menu from './Menu';
import Cart from './Cart';
import Basket from './Basket';
import Checkout from './checkout';
import Ratelogin from './Ratelogin';
import Simredirect from './Simredirect';
import Wallet from './Wallet';
import Accountdetails from './Accountdetails';
import Orders from './Orders';
import Deliveryaddress from './Deliveryaddress';
import Newdeladd from './Newdeladd';
import Watch from './watch';
import Members from './members';
import Membersdashboard from './membersdashboard';
import Videos from './videos';
import Movies from './movies';
import Livestream from './livestream';
import Gallery from './gallery';
import Musics from './music';
import Support from './support';
import TermsAndConditions from './termsandconditions';
import Privacypolicy from './privacypolicy';
import Refundpolicy from './refundpolicy';
import Mysubscription from './mysubscription';
import Moviepedia from './Moviepedia';
import Details from './Details';
import Aboutus from './aboutus';


function RouterMod(props) {

  const [cartItems, setCartItems] = useState([]);
  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }
  };
  console.warn("cartitems router", cartItems);




  return (
    <div>

      <Routes>
        <Route path="/" element={<Home cartItems={cartItems} />} />

        {/*       <Route path="/proddetails/:id/:name" element={<Proddetails cartItems={cartItems} onAdd={onAdd} />} /> */}
        <Route path="/watch" element={<Watch cartItems={cartItems} onAdd={onAdd} />} />
        <Route path="/details" element={<Details cartItems={cartItems} onAdd={onAdd} />} />
        <Route path="/ratelogin/:id" element={<Ratelogin cartItems={cartItems} />} />
        <Route path="/simredirect/:id" element={<Simredirect cartItems={cartItems} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/navhead" element={<Navhead cartItemnav={cartItems.length} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/search" element={<SearchProduct cartItems={cartItems} />} />
        <Route path="/dashboard" element={<Dashboard cartItems={cartItems} />} />
        <Route path="/membersdashboard" element={<Membersdashboard cartItems={cartItems} />} />
        <Route path="/wallet" element={<Wallet cartItems={cartItems} />} />
        <Route path="/newdeliveryaddress" element={<Newdeladd cartItems={cartItems} />} />
        <Route path="/accountdetails" element={<Accountdetails cartItems={cartItems} />} />
        <Route path="/orders" element={<Orders cartItems={cartItems} />} />
        <Route path="/deliveryaddress" element={<Deliveryaddress cartItems={cartItems} />} />
        {/* <Route path="/moviepedia" element={<Moviepedia cartItems={cartItems} />} /> */}
        <Route path="/orderonline" element={<OrderOnline cartItems={cartItems} />} />
        <Route path="/account" element={<Account cartItems={cartItems} />} />
        <Route path="/members" element={<Members cartItems={cartItems} />} />
        <Route path="/indianfoods" element={<Indian cartItems={cartItems} />} />
        <Route path="/videos" element={<Videos cartItems={cartItems} />} />
        <Route path="/movies" element={<Movies cartItems={cartItems} />} />
        <Route path="/livestream" element={<Livestream cartItems={cartItems} />} />
        <Route path="/gallery" element={<Gallery cartItems={cartItems} />} />
        <Route path="/music" element={<Musics cartItems={cartItems} />} />
        <Route path="/tandoorfoods" element={<Tandoor cartItems={cartItems} />} />
        <Route path="/vegfoods" element={<Veg cartItems={cartItems} />} />
        <Route path="/support" element={<Support cartItems={cartItems} />} />
        <Route path="/aboutus" element={<Aboutus cartItems={cartItems} />} />
        <Route path="/termsandconditions" element={<TermsAndConditions cartItems={cartItems} />} />
        <Route path="/privacypolicy" element={<Privacypolicy cartItems={cartItems} />} />
        <Route path="/refundpolicy" element={<Refundpolicy cartItems={cartItems} />} />
        <Route path="/mysubscription" element={<Mysubscription cartItems={cartItems} />} />
        <Route path="/cart" element={<Basket cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/checkout" element={<Checkout cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/menu/:id" element={<Menu cartItems={cartItems} />} />
        <Route path="/*" element={<Nopage cartItems={cartItems} />} />
      </Routes>
    </div>
  );
}
export default RouterMod;