import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function TermsAndConditions(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    const [sent, setSent] = useState(false);

    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/contactus.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    // navigate("/navhead");
                    setSent(true);

                })
            })

        }
    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms and Conditions | Starsplex - The Entertainment Verse</title>
                <meta property="title" content="Terms and Conditions | Starsplex - The Entertainment Verse" />
                <meta property="description" content="Terms and Conditions | Starsplex - The Entertainment Verse" />
            </Helmet>
            <div class="container">
                <h2>Starsplex Terms and Conditions</h2>
                <h5>Please read all the <b>Terms and Conditions</b> carefully.</h5>
                <br />
                <div id="accordion">
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                What Kind of Information Do We Collect?
                            </a>
                        </div>
                        <div id="collapseOne" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                At Starsplex We collect only those information from you at the time of registration on our site and also for place an order like subscription or tickets booking, which is essential.
                                Any data we request that is not required will be specified as optional.
                                When placeing an order or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address or phone number.However, you may also visit our site without registration.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                Payment System
                            </a>
                        </div>
                        <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                At first, When you agree to pay for the Products & Services read carefully.If renewal services are not renewed within given time, Starsplex will temporary suspend services until the invoice is paid in full.
                                Starsplex will terminate Services not paid in full 15 days after the Services have been suspended.
                                If your payment is refunded due to a chargeback you will need to pay the refunded amount plus a one time Rs 500.00 chargeback fee.
                                Starsplex accepts payments via credit cards, Debit Card, Net Banking With Our Payment Geteway.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                Contact Information
                            </a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                At Starsplex Customer agrees to keep his contact information and e-mail on file up to date at all times.
                                We will use the e-mail on file to send notifications about generated invoices, due invoices, Services suspension, Services termination, new Services provisioned and any other related information.
                                Starsplex recommends using an external e-mail address so that the Customer can receive messages in case there are problems with the Services.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                                Site Content Information
                            </a>
                        </div>
                        <div id="collapsefour" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                               The contents available on our website and mobile application are solely copyrighted under their respected company name as provided. We are simply the platforms where you may list your contents.
                               Starsplex will not take over any copyright from you guys, "Your Content Is Your Content" and you are solely responsible for that.
                            </div>
                        </div>
                    </div>     
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                Content Termination
                            </a>
                        </div>
                        <div id="collapsefive" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                               At Starsplex , if we found any copyright issues or any other issues for any content listed on our website or mobile application, we may terminate the content from our website and mobile application without any prior notice to the respective uploaders and also may take necessary legal actions against them. 
                            </div>
                        </div>
                    </div>       
                    <div class="card">
                        <div class="card-header text-left">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                                Permissions
                            </a>
                        </div>
                        <div id="collapsesix" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                               At Starsplex , to run any content on our website or mobile application, we need proper permission from the respected authorities. 
                            </div>
                        </div>
                    </div>            
                </div>

            </div>

            <Footer />

        </div>
    );
}
export default TermsAndConditions;