import React from 'react';
import './Navhead.css';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function Navhead(props) {

    const cartIte = props.cartItemnav;

    const names = JSON.parse(localStorage.getItem('user-name'));

    const navigate = useNavigate();



    console.warn("cart items of nav", cartIte);

    console.warn("header", names);
    return (
        <div>
            <nav class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">

                <div class="dropdown">
                    <button class="navbar-toggler" type="button" data-toggle="dropdown">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="dropdown-menu" style={{ width: '250px', height: '100vh', marginLeft: '-25px', overflowY: 'auto' }}>
                        <ul class="list-group">
                            <li class="list-group-item bg-dark text-white" style={{ marginTop: '-10px' }}>
                                <div>
                                    {localStorage.getItem('user-name') ?

                                        <span style={{ color: 'white', paddingRight: '5px' }} >
                                            Welcome {names}
                                        </span>

                                        :

                                        <Link class="nav-link justify-content-start" to="/account" style={{ color: 'white', fontSize: '18px', paddingRight: '5px' }} >Login</Link>


                                    }
                                </div>
    

                            </li>
                            <Link class="list-group-item list-group-item-action" to="/">Home</Link>
                            <Link class="list-group-item list-group-item-action" to="/account">My Account</Link>
                            <Link class="list-group-item list-group-item-action" to="/movies">Movies</Link>
                            <Link class="list-group-item list-group-item-action" to="/videos">Videos</Link>
                            <Link class="list-group-item list-group-item-action" to="/music">Music</Link>
                            <Link class="list-group-item list-group-item-action" to="/gallery">Gallery</Link>
                            <Link class="list-group-item list-group-item-action" to="/livestream">Live Stream</Link>
                            <Link class="list-group-item list-group-item-action" to="/aboutus">About Us</Link>
                            <a class="list-group-item list-group-item-action" href="https://starsplex.com/moviepedia/contributor/">Contributor</a>
                            <Link class="list-group-item list-group-item-action" to="/support">Support</Link>
                            <Link class="list-group-item list-group-item-action" to="/termsandconditions">Terms and Conditions</Link>
                            <Link class="list-group-item list-group-item-action" to="/privacypolicy">Privacy Policy</Link>
                            <Link class="list-group-item list-group-item-action" to="/refundpolicy">Refund Policy</Link>
                            <Link class="list-group-item list-group-item-action" to="">FAQ</Link>
                            
                        </ul>
                    </div>
                </div>


                <a id="mobileshow" class="navbar-brand" style={{ marginLeft: '5%' }} onClick={() => navigate('/')} >Starsplex</a>
                <a id="mobile" class="navbar-brand " style={{ textAlign: 'right',marginTop:"-5px" }} onClick={() => navigate('/')} >Starsplex</a>



                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" onClick={() => navigate('/movies')} >Movies</a>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/videos">Videos</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/music">Music</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/livestream">Live Stream</Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" onClick={() => navigate('/gallery')} >Gallery</a>
                        </li>
                       {/*  <li class="nav-item">
                            <a class="nav-link" href='/news' >News</a>
                        </li> */}
                       {/*  <li class="nav-item">
                            <a class="nav-link" href='/news' >News</a>
                        </li> */}
                        <li class="nav-item">
                            <a class="nav-link" href='/moviepedia' /* onClick={() => navigate('/moviepedia')} */ >Moviepedia</a>
                        </li>


                    </ul>
                </div>
                <ul class="nav navbar-nav navbar-right">
                    <form class="form-inline" style={{ marginTop: '0px' }}>
                        <Link class="nav-link" to="/search" style={{ color: 'white', paddingRight: '10px' }} ><i class="fas fa-search"></i></Link>


{/*                         <Link class="nav-link justify-content-end" style={{ width: '45px', color: 'white', float: 'left' }} to="/cart">
                            <i class="fas fa-shopping-cart"></i>

                            {cartIte ?

                                <sup><span style={{ fontSize: '7.5px', marginLeft: '-10px' }} class="badge badge-pill badge-info">{cartIte}</span></sup>

                                : ""
                            }
                        </Link> */}




                        {localStorage.getItem('user-name') ?
                            <li class="nav-item dropdown">
                                <div id="mobile">
                                    <span class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown" style={{ color: 'white', paddingRight: '5px' }} >
                                        {names} </span>


                                    <div class="dropdown-menu" style={{ marginLeft: '-50px' }}>
                                        <Link class="dropdown-item" to="/dashboard" >Dashboard</Link>
                                        <Link class="dropdown-item" to="/logout" >Logout</Link>
                                    </div>
                                </div>
                                <Link class="nav-link" to="/dashboard" id="mobileshow" style={{ color: 'skyblue', fontSize: '18px', paddingLeft: '5px' }} ><i class="fas fa-user-check"></i></Link>
                            </li>
                            :
                            <li class="nav-item">
                                <Link class="nav-link" to="/account" style={{ color: 'white', fontSize: '18px', paddingRight: '5px' }} ><i class="far fa-user-circle"></i></Link>
                            </li>

                        }


                    </form>
                </ul>

            </nav>

            <br /><br /><br />
            <div class="modal fade" id="myModalsearch" style={{ width: '300px', height: '100vh', padding: '0px', marginTop: '-5px', position: 'fixed', webkitRequestAnimationFrame: 'translate3d(-5%, 0, 0)', transform: 'translate3d(-5%, 0, 0)' }}>
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">



                        <div class="modal-body" style={{ width: '100%', height: '101vh', padding: '0px' }}>
                            <nav class="navbar bg-dark navbar-dark">
                                <ul class="navbar-nav">
                                    <li class="nav-item" routerLink="" style={{ color: '#FFF', fontSize: '20px' }}>Starsplex</li>
                                    <li class="nav-item d-flex justify-content-end" style={{ position: 'absolute', top: '15px', right: '5px', fontSize: '36px', marginLeft: '50px' }}>
                                        <button type="button" class="close text-white" data-dismiss="modal" style={{ color: '#FFF' }}>x</button>
                                    </li>
                                    {localStorage.getItem('user-name') ?
                                        <li style={{ color: 'white', paddingLeft: '10px' }}>
                                            <span>Welcome {names}</span>
                                        </li>
                                        :
                                        <li class="nav-item" data-dismiss="modal">
                                            <Link class="nav-link" to="/orderonline">Order Online</Link>
                                        </li>

                                    }



                                </ul>
                            </nav>

                            <ul class="list-group d-flex justify-content-start">
                                <li class="list-group-item d-flex justify-content-start"><Link class="nav-link" to="/orderonline">Order Online</Link></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="movies" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Movies</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="videos" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Videos</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="music" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Musics</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="livestream" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Live Stream</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="gallery" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Gallery</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="faq" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>FAQ</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="privacypolicy" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Privacy Policy</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="termsofservice" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Terms of Service</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="refundpolicy" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Refund Policy</a></li>
                                <li class="list-group-item d-flex justify-content-start"><a routerLink="contactus" data-dismiss="modal" style={{ fontSize: '18px', color: 'black' }}>Support</a></li>
                            </ul>

                        </div>

                    </div>
                </div>
            </div>




        </div>
    );
}
export default Navhead;