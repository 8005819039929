import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

function Aboutus(props) {
    let cartItems = props.cartItems;

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [emailreg, setEmailreg] = useState("");
    const [passwordreg, setPasswordreg] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logstatus, setLogstatus] = useState("");
    const [Signupstatus, setSignupstatus] = useState("");
    const [Loginstatus, setLoginstatus] = useState("");
    const [sent, setSent] = useState(false);

    function saveUser() {
        console.warn(fname, lname, emailreg, passwordreg, mobile);

        let data = { fname, lname, emailreg, passwordreg, mobile };

        if (fname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (lname === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (emailreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (passwordreg === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else if (mobile === "") {
            setSignupstatus("Please Complete All The Input Fields");
        }
        else {


            fetch("https://www.starsplex.com/api/contactus.php", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)


            }).then((result) => {
                // console.warn("result",result);
                result.json().then((resp) => {
                    console.warn("resp", resp);
                    // navigate("/navhead");
                    setSent(true);

                })
            })

        }
    }

    /*     function userLogin() {
            console.warn(email, password);
    
            let data = { email, password };
    
            if (email === "") {
                setLoginstatus("Please Enter Valid Email ID & Password");
            }
            else if (password === "") {
                setLoginstatus("Please Enter Valid Email ID & Password");
            }
            else {
    
                fetch("https://www.starsplex.com/api/memberlogin.php", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
    
    
                }).then((result) => {
                    // console.warn("result",result);
                    result.json().then((resp) => {
                        console.warn("resp", resp);
                        if (resp !== "NO") {
                            localStorage.setItem("user-name", JSON.stringify(resp));
                            localStorage.setItem("emaillocal", JSON.stringify(email));
                            localStorage.setItem("user-info", JSON.stringify(resp));
    
                            // navigate("/navhead");
                            navigate("/membersdashboard");
                            console.warn("Login", resp);
    
                        }
                        else {
                            console.warn("Login Failed!");
                            setLogstatus("Failed");
                        }
    
    
                    })
                })
            }
        }
     */

    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Starsplex - The Entertainment Verse</title>
                <meta property="title" content="Support Center | Starsplex - The Entertainment Verse" />
                <meta property="description" content="Support Center | Starsplex - The Entertainment Verse" />
            </Helmet>
            <div class="container">
                <h2>About Starsplex</h2>
                <br />

                <div class="container"><br />

                    <div class="col-sm-8 d-block mx-auto">

                        <p style={{ textAlign: 'left' }}>
                            Starsplex is a community database platform for all forms of entertainment and their related businesses, information across all current and emerging media and platforms where everyone can contribute entertainment related information that matters to them.
                            <br />At Starsplex ownership of all external information and content belongs to its rightful owner. In spite of this, if anyone has any issues with any information or content, they can feel free to contact us.
                        </p>
                        <p style={{ textAlign: 'left' }}>
                            Every bit of information and content on starsplex are based on facts, references and external links in an organized manner.
                        </p>

                    </div>
                    <div style={{ marginTop: "25px" }}>
                        <h6><b>Note:</b><br /> For any queries please feel free to contact us at <b>starsplexofficial@gmail.com</b></h6>
                    </div>

                    <div style={{ height: '100px', marginTop: '50px' }}>
                        {
                            sent ?
                                <div class="alert alert-success alert-dismissible">
                                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                                    <strong>Sent!</strong>  Ticket raised successfully!
                                </div>
                                : ""
                        }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    );
}
export default Aboutus;