import React from 'react';

import Navhead from './Navhead';

import { Helmet } from 'react-helmet';

export default function OrderOnline(props) {
   let cartItems = props.cartItems;
   return (
      <div>
         <Navhead cartItemnav={cartItems.length} />
         <Helmet>
            <meta charSet="utf-8" />
            <title>Book Tickets - Starsplex The Entertainment Verse</title>
            <meta property="title" content="Order Online - Starsplex The Entertainment Verse" />
            {/*  <meta property="og:image" content="<img src='/assets/icon.png' />" />
                            <meta property="og:url" content="https://www.foodyinn.in/" /> */}
            <meta property="description" content="Starsplex The Entertainment Verse" />
         </Helmet>
         <div class="container-fluid">
            <div class="container">
               <br /><br />
               <div class="row">
                  <div class="col-sm-12 d-flex justify-content-center">
                     <p style={{ fontSize: "3vw" }}>Book Tickets</p>
                  </div>
               </div>

               <div class="row">
                  <div class="col-sm-12 d-flex justify-content-center">
                    <p>No Booking Event Available Now!</p>
                  </div>
                  <div class="col-sm-6">
                    
                  </div>
                  <div class="col-sm-6">
                    
                  </div>
               </div>

            </div>
         </div>
      </div>
   );

}