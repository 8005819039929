import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate();
  let currentYear = new Date().getFullYear()
  return (
    <div>

      <footer class="page-footer font-small desktopview" style={{ backgroundColor: '#333333', color: 'white', marginTop: '50px' }}>

        <div style={{ backgroundColor: '#666666' }}>
          <div class="container">


            <div class="row py-4 d-flex align-items-center">


              <div class="col-lg-12 text-center">
                <h5 class="mb-0 d-flex justify-content-center" style={{ color: '#FFF' }}>Get In Touch With Us</h5>
              </div>

            </div>


          </div>
        </div>


        <div class="container text-center text-md-left mt-5">


          <div class="row mt-3">


            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">


              <h6 class="text-uppercase font-weight-bold">STARSPLEX</h6>
              <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
              <p>Starsplex is a community database platform for all forms of entertainment and their related businesses, information across all current and emerging media and platforms where everyone can contribute entertainment related information that matters to them{/* , SImply "Your Digital Theater" */}.</p>

            </div>
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">


              <h6 class="text-uppercase font-weight-bold">Products</h6>
              <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />
              <p>
                <a onClick={() => navigate('/videos')} style={{ color: '#FFFFFF' }}>Movies</a>
              </p>
              <p>
                <a href="category?id=2/welcome/Living Room/" style={{ color: '#FFFFFF' }}>Videos </a>
              </p>
              <p>
                <a href="category?id=3/welcome/Dining/" style={{ color: '#FFFFFF' }}>Music</a>
              </p>
              <p>
                <a href="about?welcome/AboutUs/" style={{ color: '#FFFFFF' }}>Gallery</a>
              </p>
              <p>
                <a href="about?welcome/livestream/" style={{ color: '#FFFFFF' }}>Live Stream</a>
              </p>
              <p>
                <a href="https://starsplex.com/moviepedia/contributor/" style={{ color: '#FFFFFF' }}>Contributor</a>
              </p>

              <p>
                <a href="/moviepedia" style={{ color: '#FFFFFF' }}>Moviepedia</a>
              </p>

            </div>

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">


              <h6 class="text-uppercase font-weight-bold">Useful links</h6>
              <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />
              <p>
                <a onClick={() => navigate('/account')} style={{ color: '#FFFFFF' }}>My Account</a>
              </p>
              <p>
                <a onClick={() => navigate('/members')} style={{ color: '#FFFFFF' }}>Associate Members</a>
              </p>
              <p>
                <a onClick={() => navigate('/privacypolicy')} style={{ color: '#FFFFFF' }}>Privacy Policy</a>
              </p>
              <p>
                <a onClick={() => navigate('/termsandconditions')} style={{ color: '#FFFFFF' }}>Terms of Service</a>
              </p>
              <p>
                <a onClick={() => navigate('/refundpolicy')} style={{ color: '#FFFFFF' }}>Refund Policy</a>
              </p>
              {/*  <p>
                <a href="/news" style={{ color: '#FFFFFF' }}>News</a>
              </p> */}
              <p>
                <a onClick={() => navigate('/aboutus')} style={{ color: '#FFFFFF' }}>About Us</a>
              </p>
              
              <p>
                <a onClick={() => navigate('/support')} style={{ color: '#FFFFFF' }}>Contact Us</a>
              </p>

            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">


              <h6 class="text-uppercase font-weight-bold">Contact</h6>
              <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px' }} />
              <p>
                <i class="fa fa-home"></i> starsplexofficial@gmail.com</p>

              {/*       <p>
          <i class="fa fa-phone-square"></i> +91 9143126662</p> */}

              <img src="https://www.foodyinn.in/image/Payments.png" width="100%" />
            </div>


          </div>


        </div>



        <div class="footer-copyright text-center py-3" style={{ backgroundColor: '#000000', fontSize: '14px', letterSpacing: "2px" }}>© {currentYear} STARSPLEX. The ownership of all external content or information belongs to its rightful owner. {/* Design and Developed by team
          <a href="https://www.glamsure.in/" style={{ color: '#FFFFFF' }}> Glamsure</a> */}
        </div>


      </footer>

      <nav id="mview" class="navbar bg-dark navbar-dark fixed-bottom mobileview" style={{ height: '60px', padding: '0px' }}>
        <div class="d-flex justify-content-center p-0">
          <ul id="bottommenu" style={{ listStyleType: 'none', marginTop: '9px' }} class="px-0">
            <li class="nav-item" onClick={() => navigate("/")}>
              <a >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                </svg>
                <p style={{ fontSize: '10px', marginTop: '0px' }}>Home</p></a>
            </li>
            <li class="nav-item" onClick={() => navigate("/movies")}>
              <a >
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-film" viewBox="0 0 16 16">
                  <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z" />
                </svg>
                <p style={{ fontSize: '10px', marginTop: '3px' }}>Movies</p></a>
            </li>
            <li class="nav-item" style={{ marginTop: '-2px' }} /* onClick={() => navigate("/moviepedia")} */>
              <a href='/moviepedia'>
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor" class="bi bi-tv" viewBox="0 0 16 16">
                  <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                </svg>
                <p style={{ fontSize: '10px', marginTop: '-2px' }}>Moviepedia</p></a>
            </li>
            <li class="nav-item" onClick={() => navigate("/videos")}>
              <a >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-camera-video-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z" />
                </svg>
                <p style={{ fontSize: '10px', marginTop: '0px' }}>Videos</p></a>
            </li>
            <li class="nav-item" onClick={() => navigate("/music")}>
              <a >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-music-note-beamed" viewBox="0 0 16 16">
                  <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z" />
                  <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z" />
                  <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z" />
                </svg>
                <p style={{ fontSize: '10px', marginTop: '0px' }}>Musics</p></a>
            </li>
          </ul>
        </div>
      </nav>

    </div>
  )
}

export default Footer;