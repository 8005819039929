import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Filterside } from './Filterside';
import Footer from './Footer';
import Navhead from './Navhead';
import { Helmet } from 'react-helmet';

export function Veg(props) {

  const [foodcat, setFoodcat] = useState([]);
  let cartItems = props.cartItems;

  useEffect(() => {

    fetch("https://foodyinn.in/api/fetchproductsveg.php").then((resulttandoor) => {
      resulttandoor.json().then((resptandoor) => {

        setFoodcat(resptandoor);
        console.warn("RESPtandoor", resptandoor);
      })
    });

  },)

  return (
    <div>
      <Navhead cartItemnav={cartItems.length} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Veg Foods - Starsplex The Entertainment Verse</title>
        <meta property="title" content="Veg Foods - Starsplex The Entertainment Verse" />
        {/*  <meta property="og:image" content="<img src='/assets/icon.png' />" />
                            <meta property="og:url" content="https://www.foodyinn.in/" /> */}
        <meta property="description" content="Starsplex The Entertainment Verse" />
      </Helmet>

      <div class="container product-page" id="top">
        <div class="row">
          <div class="col-sm-12" align={'left'}>
            <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
            <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>Veg  </Link>

          </div>
        </div>

      </div>


      <div class="container-fluid product">
        <div class="container">
          <div class="row">

            <div class="col-sm-3" id="sidebar">
              <Filterside />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-12" >
                  <p>
                    <h3 class="justify-content-start">Veg</h3>
                  </p>
                </div>
              </div>

              <div class="row d-flex justify-content-center">
                <div class="col-sm-12">

                  {
                    foodcat.map((item) =>
                      <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                        <div style={{ width: '155px', marginLeft: '10px', float: 'left', paddingBottom: '10px' }}>
                          <img id="itemmobile" src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "120px", width: "150px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                          <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                        </div>
                      </NavLink>

                    )
                  }


                </div>
              </div>







            </div>
          </div>
          <div class="col-sm-4">

          </div>




        </div>
      </div>


      <Footer />
    </div>
  )
}